@import './mixins.scss';

.bg-dark {
  @include bgColorSet(header);
}

.navbar-dark {
  padding: 12px 15px;
}

.uncolored {
  margin-bottom: 0px;
  @include colorSet(primaryText);
  
  &:hover {
    @include colorSet(link-hover);
    text-decoration: none;
  }
}

a, .likeALink {
  @include colorSet(link);
  cursor: pointer;
}

a dt,
dt>a,
dd>a,
.breadcrumb-item>a {
  @include colorSet(link);
}

dd {
  margin-left: 1em;
  margin-bottom: 0em;
  @include colorSet(secondaryText);

  &:last-child {
    margin-bottom: -1em;
  }
}

.expired {
  text-decoration: line-through;
  font-style: italic;
  @include colorSet(error);
}

tbody>tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.block-ui-overlay {
  @include bgColorSet(darkenedBg);
}

.custom-range {
  width: inherit;
  padding: 12px 20px 0px 10px;
}

.nav-tabs {
  @include bgColorSet(darkenedBg);
  font-size: 90%;
}

.nav-link:not(.active) {
  font-style: italic;
}

.navbar-nav .nav-link:not(.active) {
  @include colorSet(navLink);
}

.pagination-container {
  padding: 20px 0px 12px 0px;
}

.pagination-row {
  display: flex;
  justify-content: center;
}

.pagination-row > .pagination {
  margin-bottom: 5px;
}

.pagination {
  & .page-link {
    @include borderSet(lightBorder);
    border-style: solid !important;
    margin-left: -1px !important;
    border-width: 1px !important;

    @include colorSet(primaryText);
    &:hover {
      @include colorSet(link-hover);
    }
  }

  & > .page-item > .page-link {
    @include bgColorSet(paginationBtn);
  }

  & > .disabled > .page-link {
    @include bgColorSet(paginationBtnDisabled);
  }

  & > .active > .page-link {
    @include bgColorSet(paginationBtnActive);
    @include colorSet(invertedText);
  }
}

.pagination-subtext {
  font-weight: bold;
  font-size: 80%;
}

.titlebar {
  margin: 12px 5px;
  padding-right: 25px;
}

.infomsg {
  @include colorSet(secondaryText);
  font-weight: normal;
  font-style: italic;
  font-size: 80%;
  padding: 12px 25px;
}

.infomsg-small {
  @include colorSet(secondaryText);
  font-style: italic;
  font-size: 80%;
  padding: 0px 8px;
}

.eventmsg {
  @include colorSet(secondaryText);
  font-style: italic;
  font-size: 80%;
  padding: 0px;
}

.eventdatemsg {
  @include colorSet(secondaryText);
  font-style: italic;
  font-size: 12px;
  padding: 0px;
}

.reportmsg {
  @include colorSet(secondaryText);
  font-style: italic;
  font-size: 80%;
  padding: 0px;
}

.severitylow {
  @include colorSet(severityLowText);
}

.severitymedium {
  @include colorSet(severityMediumText);
}

.severityhigh {
  @include colorSet(severityHighText);
}

.errormsg {
  @include colorSet(error);
  font-style: italic;
  font-size: 12px;
}

.mini-input {
  width: 4em;
}

.mid-input {
  width: 12em;
}

.file-input {
  padding-bottom: 36px;
}

div.react-datepicker-wrapper, div.react-datepicker__input-container {
  display: inline;
}

.react-datepicker-wrapper {
  width: 100%;
}

.custom-progress-bar {
  padding: 10px 5px;
}

.custom-progress-bar .progress,
.volume-meter .progress {
  margin: 4px;
  @include bgColorSet(lightBg);
}

.small-label {
  font-size: 80%;
  @include colorSet(secondaryText);
  padding-left: 5px;
  padding-right: 10px;
}

.stream-label {
  padding: 2px 5px;
}

.volume-meter {
  padding: 5px 5px 5px 0px;
}

.volume-meter > .progress {
  height: 8px;
  width: 100px;
}

.progress-bar.bg-success {
  background-color: #70bb82 !important;
}

h3 .badge-light {
  background-color: #dee4e9;
  font-size: 14px;
  color: #4e4e6e;
}

.single-button-row {
  padding: 15px;
}

.video-container {
  text-align: center;
}

.media-container {
  width: 100%;
  max-width: 480px;
}

.upload-error {
  color: #800000;
  padding: 10px 18px;
}

.question-text {
  display: inline-block;
  padding-bottom: 8px;
  width: 100%;
}

.question-text textarea {
  width: 100%;
}

.mini-jumbotron {
  padding: 12px;
}

.examdescription dd:last-child {
  margin-bottom: 0px;
}

.examdescription>dt, .examdescription>span>dt {
  float: left;
  width: 45%;
  text-align: right;
  margin-right: 20px;
}

.examdescription>dd, .examdescription>span>dd {
  margin-bottom: 8px;
}

.description {
  margin-bottom: 50px;
}

.examdescription>span>dd.streamobjects {
  margin-bottom: 0px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #135988;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li .timeline-question {
  color: #000000;
  padding-bottom: 6px;
}

ul.timeline > li .timeline-event {
  padding-left: 32px;
  font-style: italic;
}

.close {
  float: none;
  @include colorSet(primaryText);
}

.dynamic-search, .dynamic-filter, .dynamic-sort {
  margin: 4px;
}

.dynamic-search, input, select {
  @include colorSet(secondaryText);
  @include borderSet(lightBorder);
  @include bgColorSet(lightBg);
}

input:focus, select:focus {
  @include colorSet(primaryText);
  @include borderSet(lightBorder);
  @include bgColorSet(lightBg);
}

input[type="range"] {
  background-color: transparent;
  &:focus {
    background-color: transparent;
  }
}

.dynamic-search {
  padding-left: 4px;
  border-radius: .2rem;
  width: 300px;
}

input::placeholder, .dynamic-search::placeholder {
  font-size: 80%;
  font-style: italic;
  @include colorSet(secondaryText);
}

input.form-control {
  width: 100%;
  min-width: 200px;
}

.objectstreams {
  @include colorSet(secondaryText);
}

.btn-outline-secondary {
  @include colorSet(secondaryText);
  @include borderSet(secondaryText);
}

.dropdown-item {
  @include colorSet(primaryText);
  font-size: 80%;
  cursor: pointer;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #6390c0;
}

/* wrappers around btns */

.btn-group > :not(:last-child) .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > :not(:first-child) .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > :not(:first-child) .btn {
  margin-left: -1px;
}


/* tooltip settings */

.tooltip-inner > ul {
  padding-left: 9px;
  margin-bottom: 0;
}

.ace_hidden-cursors {
  opacity: 0;
}

.wide-modal-dialog .modal-dialog {
  max-width: 750px;
}

/* checklist */

ul.checklist {
  list-style-type: none;
}

ul.checklist li:before {
  content: '✓ '; 
}

.attention {
  @include colorSet(error);
  font-weight: bold;
  font-size: 125%;
}
