/**
* Color settings and mixins
*/

$colors-light: (
  header: #343a40,
  primaryText: #1d1e1f,
  secondaryText: #74747a,
  navLink: #e9e9e9,
  invertedText: #ebebeb,
  darkenedBg: #f3f9fb,
  lightBg: #f8f8f8,
  lightBorder: #b2b2bb,
  link: #295ead,
  link-hover: #1f4785,
  error: #883333,
  paginationBtn: #ffffff,
  paginationBtn-hover: #dbd8d8,
  paginationBtnDisabled: #dbd8d8,
  paginationBtnActive: #3c5c7e,
  paginationBtnActive-hover: #3c5c7e,
  severityLowText: #a1a119,
  severityMediumText: #aa7108,
  severityHighText: #883333,
);

$colors-dark: (
  header: #536372,
  primaryText: #d5dbe0,
  secondaryText: #a1a1a7,
  navLink: #a1a1a7,
  invertedText: #343436,
  darkenedBg: #343436,
  lightBg: #1e1e1f,
  lightBorder: #56565a,
  link: #58b2ee,
  link-hover: #89c0ff,
  error: #e74c3c,
  paginationBtn: #343436,
  paginationBtn-hover: #67677a,
  paginationBtnDisabled: #67677a,
  paginationBtnActive: #c2dfff,
  paginationBtnActive-hover: #c2dfff,
  severityLowText: #e4e433,
  severityMediumText: #dfa53a,
  severityHighText: #e74c3c,
);

/**
 * Helper mixins
 */

@mixin propSet($property, $colorName) {
  .cvqLightMode & {
    #{$property}: map-get($colors-light, $colorName);
    &:hover {
      #{$property}: map-get($colors-light, $colorName#{-hover});
    }
  }
  
  .cvqDarkMode & {
    #{$property}: map-get($colors-dark, $colorName);
    &:hover {
      #{$property}: map-get($colors-dark, $colorName#{-hover});
    }
  }
}

@mixin colorSet($colorName) {
  @include propSet(color, $colorName);
}

@mixin bgColorSet($colorName) {
  @include propSet(background-color, $colorName);
}

@mixin borderSet($colorName) {
  @include propSet(border-color, $colorName);
}
