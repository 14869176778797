.question-points {
  padding: 15px 0px;
  font-weight: bold;
}

.inline-form {
  display: inline;
}

.comment-block {
  padding: 15px 35px;
  color: grey;
}

.large-hr {
  margin-top: 30px;
}